import React from 'react';
import './../css/Timeline.css';
import ironMan from './../img/ironman.png';
import avengers from './../img/avengers.jpeg';
import winterSoldier from './../img/winterSoldier.jpeg';
import ageOfUltron from './../img/ageOfUltron.jpeg';
import civilWar from './../img/civilWar.jpeg';
import blackPanther from './../img/blackPanther.png';
import guardians from './../img/guardians.jpg';
import ragnorak from './../img/ragnorak.jpeg';
import drStrange from './../img/drStrange.jpeg';
import infinityWar from './../img/infinityWar.jpg';
import antManAndTheWasp from './../img/antManAndTheWasp.jpg';
import captainAmericaTheFirstAvenger from './../img/captainAmericaTheFirstAvenger.jpg';
import captainMarvel from './../img/captainMarvel.jpeg';
import antman from './../img/antman.jpeg';
import endgame from './../img/endgame.jpg';
import guardians2 from './../img/guardians2.jpeg';
import hulk from './../img/incredibleHulk.jpeg';
import ironMan2 from './../img/ironMan2.jpg';
import ironMan3 from './../img/ironMan3.jpeg';
import spidermanHomecoming from './../img/spidermanHomecoming.jpeg';
import thor from './../img/thor.jpeg';
import thor2 from './../img/thor2.jpeg';
import marvel from './../img/marvel.jpeg';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

class Timeline extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalOpen: false,
            events: [],
            suggestions: [],
            posterMap: [
                {
                    "Iron Man": ironMan,
                    "Captain America: The First Avenger": captainAmericaTheFirstAvenger,
                    "Captain Marvel": captainMarvel,
                    "Avengers": avengers,
                    "Captain America: Winter Soldier": winterSoldier,
                    "Avengers: Age of Ultron": ageOfUltron,
                    "Captain America: Civil War": civilWar,
                    "Black Panther": blackPanther,
                    "Guardians of the Galaxy": guardians,
                    "Thor: Ragnorak": ragnorak,
                    "Dr. Strange": drStrange,
                    "Avengers: Infinity War": infinityWar,
                    "Ant-Man and The Wasp": antManAndTheWasp,
                    "Avengers: Endgame": endgame,
                    "Ant-Man": antman,
                    "Iron Man 2": ironMan2,
                    "Iron Man 3": ironMan3,
                    "Spiderman: Homecoming": spidermanHomecoming,
                    "Thor": thor,
                    "Thor: The Dark World": thor2,
                    "The Incredible Hulk": hulk,
                    "Guardians of the Galaxy Vol. 2": guardians2
                }
            ]
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.loadData = this.loadData.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
        this.displaySuggestions = this.displaySuggestions.bind(this);
        this.useSuggestion = this.useSuggestion.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    toggleModal(object){
        if (object && object.type && object.type.S) {
            this.setState({
                modalOpen: !this.state.modalOpen,
                eventType: object.type.S,
                movieTitle: object.movieTitle.S,
                eventYear: object.eventYear.N,
                content: object.content.S,
                suggestions: [],
                showError: false
            });
        } else {
            this.setState({
                modalOpen: !this.state.modalOpen,
                eventType: "",
                movieTitle: "",
                eventYear: "",
                content: "",
                suggestions: [],
                showError: false
            });
        }
    }

    loadData(){
        this.props.timelineService.getAllTimelineEvents().then(function(response){
            let items = response.data.Items;
            let sortedItems = items.sort(function(a, b){
                return parseInt(b.eventYear.N) - parseInt(a.eventYear.N);
            });
            let movieTitles = [];
            for (let i in sortedItems){
                movieTitles.push(sortedItems[i].movieTitle.S);
            }
            for (let key in this.state.posterMap[0]) {
                if (!movieTitles.includes(key)) {
                    movieTitles.push(key);
                }
            }
            this.setState({
                events: sortedItems,
                movieTitles: movieTitles
            });
        }.bind(this));
    }

    saveEvent(){
        let type = document.getElementById("eventType").value;
        let movieTitle = document.getElementById("movie").value;
        let eventYear = document.getElementById("year").value.toString();
        let content = document.getElementById("description").value;
        let password = document.getElementById("password").value;
        let timelineEvent = {
            type,
            movieTitle,
            eventYear,
            content
        }
        this.props.timelineService.addOrUpdateEvent(timelineEvent, password).then(function(){
                this.loadData();
                this.toggleModal();
            }.bind(this),
            function(error, response){
                this.setState({
                    showError: true
                });
            }.bind(this)
        );
    }

    displaySuggestions(){
        let text = document.getElementById("movie").value;
        if (text.length > 3){
            let suggestions = [];
            for (let i in this.state.movieTitles){
                let movieTitle = this.state.movieTitles[i];
                if (movieTitle.includes(text)){
                    let key = movieTitle + "suggestion" + i;
                    suggestions.push(
                        <div key={key} className="suggested-title" onClick={this.useSuggestion.bind(this, movieTitle)}>
                            {movieTitle}
                        </div>
                    );
                }
            }
            this.setState({
                suggestions
            });
        } else {
            this.setState({
                suggestions: []
            });
        }
    }

    useSuggestion(title) {
        document.getElementById("movie").value = title;
        this.setState({
            suggestions: []
        });
    }

    render() {
        let posterMap = this.state.posterMap[0];
        let rows = [];
        for (let i in this.state.events){
            let object = this.state.events[i];
            if (object.type.S === "movie") {
                let poster = posterMap[object.movieTitle.S];
                if (!poster) {
                    // default poster
                    poster = marvel;
                }
                rows.push(
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', paddingTop: '10px', fontSize: '15px'}}
                        icon={object.eventYear.N}
                        key={i}
                    >
                        <div hidden={!this.props.isEditEnabled} className="edit-button-container">
                            <IconButton
                                color="inherit"
                                onClick={this.toggleModal.bind(this, object)}
                                edge="start"
                            >
                                <EditIcon />
                            </IconButton>
                        </div>
                        <div className="timeline-movie-title">{object.movieTitle.S}</div>
                        <div className="timeline-poster-wrapper">
                            <img src={poster} alt="ironMan" className="timeline-movie-cover"></img>
                        </div>
                        <div className="timeline-content-wrapper">
                            {object.content.S}
                        </div>
                    </VerticalTimelineElement>
                )
            } else if (object.type.S === "event") {
                rows.push(
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        key={i}
                    >
                        <div hidden={!this.props.isEditEnabled} className="edit-button-container">
                            <IconButton
                                color="inherit"
                                onClick={this.toggleModal.bind(this, object)}
                                edge="start"
                            >
                                <EditIcon />
                            </IconButton>
                        </div>
                        <div className="subtimeline-year">{object.eventYear.N}</div>
                        <div className="subtimeline-content">
                            {object.content.S}
                        </div>
                        <div className="subtimeline-movie-title">
                            {object.movieTitle.S}
                        </div>
                    </VerticalTimelineElement>
                )
            } else {
                console.log("Type not supported!")
            }
        }
        return (
            <div>
                <div className="timeline-subheader">
                    <div className="timeline-subheader-text">Timeline for Girlfriends</div>
                    <div className="timeline-by-header">by Hailee</div>
                </div>
                <div hidden={!this.props.isEditEnabled} className="add-button-container">
                    <IconButton
                        color="primary"
                        onClick={this.toggleModal}
                        edge="start"
                    >
                        <AddIcon /> Add New Event
                    </IconButton>
                </div>
                <div className='timeline-container'>
                    <VerticalTimeline>
                        {rows}
                    </VerticalTimeline>
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.modalOpen}
                    onClose={this.toggleModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modalOpen}>
                        <div className="event-modal-container" style={this.state.modalOpen ? {} : { display: 'none' }}>
                            <div className="input-container">
                                <select id="eventType" name="eventType" defaultValue={this.state.eventType}>
                                    <option value="movie">Movie</option>
                                    <option value="event">Event</option>
                                </select>
                            </div>
                            <div className="input-container">
                                <input id="movie" type="text" placeholder="Select a movie..." onInput={this.displaySuggestions} defaultValue={this.state.movieTitle}></input>
                                {this.state.suggestions}
                            </div>
                            <div className="input-container">
                                <input id="year" type="number" placeholder="Select a year..." defaultValue={this.state.eventYear}></input>
                            </div>
                            <div className="input-container">
                                <textarea id="description" name="description" rows="4" cols="25" placeholder="Enter your description..." defaultValue={this.state.content}></textarea>
                            </div>
                            <div className="input-container">
                                <input id="password" type="text" placeholder="Enter the password..."></input>
                            </div>
                            <div className="save-button-container">
                                <IconButton
                                    color="primary"
                                    onClick={this.saveEvent}
                                    edge="start"
                                >
                                    <SaveIcon /> Save
                                </IconButton>
                            </div>
                            <div className="error-container" style={this.state.showError ? {} : { display: 'none' }}>
                                Error: failed to save changes, double check the password or text Seth
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

export default Timeline;