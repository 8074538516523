import React from 'react';
import './../css/Home.css';
import ironMan from './../img/ironman.png';
import avengers from './../img/avengers.jpeg';
import winterSoldier from './../img/winterSoldier.jpeg';
import ageOfUltron from './../img/ageOfUltron.jpeg';
import civilWar from './../img/civilWar.jpeg';
import blackPanther from './../img/blackPanther.png';
import guardians from './../img/guardians.jpg';
import ragnorak from './../img/ragnorak.jpeg';
import drStrange from './../img/drStrange.jpeg';
import infinityWar from './../img/infinityWar.jpg';
import antManAndTheWasp from './../img/antManAndTheWasp.jpg';
import endgame from './../img/endgame.jpg';

class Home extends React.Component {
   render() {
      return (
         <div className='home-container'>
            <div className="sub-header">
               Infinity Saga Catchup!
            </div>
            <div className="home-description">
               For all those girlfriends who need to catch up with the Marvel Cinematic Universe without watching all 25 movies.
            </div>
            <div>
               <div>
                  <div className="movie-grid-item">
                     <img src={ironMan} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Iron Man</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> N/A</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={avengers} alt="avengers" className="movie-cover"></img>
                     <div className="movie-title">Avengers</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> Thor is the god of thunder, his brother Loki is evil. Captain America is a super soldier from the 40s who was frozen and thawed out in 2012. Bruce Banner is a scientist who becomes the Hulk when he's angry.</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={winterSoldier} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Captain America: Winter Soldier</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> Hydra is an evil organization who Cap defeated in his first movie and Bucky Barnes, Caps best friend, was lost in the war.</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={ageOfUltron} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Avengers: Age of Ultron</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> N/A</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={civilWar} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Captain America: Civil War</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> Ant-Man is a super hero who can shrink down to the size of an Ant.</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={blackPanther} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Black Panther</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> N/A</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={guardians} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Guardians of the Galaxy</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> N/A</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={ragnorak} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Thor: Ragnorak</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> Loki has become a good guy and is friends with Thor. Thor and Loki's mother was killed in the first Thor movie.</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={drStrange} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Dr. Strange</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> N/A</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={infinityWar} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Avengers: Infinity War</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> N/A</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={antManAndTheWasp} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Ant-Man and the Wasp</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> Ant-Man was in lockdown during Infinity War and the Wasp was on the run. They were together for Ant-Man, but now broken up in Ant-Man and the Wasp.</div>
                  </div>
                  <div className="movie-grid-item">
                     <img src={endgame} alt="ironMan" className="movie-cover"></img>
                     <div className="movie-title">Avengers: Endgame</div>
                     <div className="context"><span className="what-you-need-to-know">What You Need To Know:</span> Captain Marvel is a boss ass bitch who got her powers from the Space Stone.</div>
                  </div>
               </div>
            </div>
            <div>
               <div>
                  <div className="sub-header">
                     What's Next!
                  </div>
                  <div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Spiderman: Far From Home</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Eternals</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Shang Chi and the Legend of the Ten Rings</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Spiderman: No Way Home</div>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <div>
                  <div className="sub-header">
                     Movies to Skip
                  </div>
                  <div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Captain America: The First Avenger</div>
                        <div className="context">Why You Don't Need This Movie: As long as you know Captain America is a super soldier and Hydra are evil, this movie doesn't add much.</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">The Incredible Hulk</div>
                        <div className="context">Why You Don't Need This Movie: This movie is bad... Just know Bruce Banner becomes the Hulk when he's angry.</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Thor</div>
                        <div className="context">Why You Don't Need This Movie: As long as you know Thor is a god, then Avengers covers the Thor qualities</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Iron Man 2</div>
                        <div className="context">Why You Don't Need This Movie: This is a continuation of Iron Man, no real value added to the MCU.</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Iron Man 3</div>
                        <div className="context">Why You Don't Need This Movie: Age of Ultron covers all the controversies covered in Iron Man 3</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Thor 2: The Dark World</div>
                        <div className="context">Why You Don't Need This Movie: This movie is really bad... they never reference its story again.</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Guardians of the Galaxy Vol 2</div>
                        <div className="context">Why You Don't Need This Movie: You should watch this movie, its awesome, but not needed for the catchup</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Ant-Man</div>
                        <div className="context">Why You Don't Need This Movie: Ant-Man and the Wasp covers the Quantum realm so nothing new in Ant-Man</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Spiderman: Homecoming</div>
                        <div className="context">Why You Don't Need This Movie: If you know Spiderman's powers, then you don't need this movie, but its good :)</div>
                     </div>
                     <div className="movie-grid-item">
                        <div className="movie-title">Captain Marvel</div>
                        <div className="context">Why You Don't Need This Movie: Another good movie that should be watched, but not needed for the Infinity Saga rewatch, just know Captain Marvel is a badass.</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default Home;