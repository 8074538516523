class TimelineService {
    constructor(amplifyRequestService, apiName) {
      this.amplifyRequestService = amplifyRequestService;
      this.apiName = apiName;
    }

    getAllTimelineEvents() {
        return this.amplifyRequestService.request(this.apiName, '/timeline-events', "GET");
    }

    addOrUpdateEvent(timelineEvent, password) {
        return this.amplifyRequestService.request(this.apiName, '/timeline-events', "POST", {
            body: {
                "timelineEvent": timelineEvent,
                "password": password
            }
        });
    }
  
  }
  
  export default TimelineService;