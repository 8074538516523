import React from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
import Home from './Home';
import Timeline from './Timeline';

class AppRouter extends React.Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/timeline/edit">
                        <Timeline
                            isEditEnabled="true"
                            timelineService={this.props.timelineService}
                        >
                        </Timeline>
                    </Route>
                    <Route path="/timeline">
                        <Timeline
                            timelineService={this.props.timelineService}
                        >
                        </Timeline>
                    </Route>
                    <Route path="/">
                        <Home
                        >
                        </Home>
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default AppRouter;